body {
  margin: 0;
}

.root {
  background-color: #121212;
}

@font-face {
  font-family: 'Afacad';
  src: local('Afacad'), url(./fonts/Afacad.ttf) format('tff')
}
